import React from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Container, Nav, NavItem, NavLink } from 'reactstrap';

import './Tdsheader.css';

import smoothscroll from 'smoothscroll-polyfill';

import { StaticImage } from 'gatsby-plugin-image';

const logo = '../images/logowhite.png';
const logoBlack = '../images/logoblack.png';

class Tdsheader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            activeMenu: props.active,
            isHome: props.isHome
        }
    }

    componentDidMount() {
        smoothscroll.polyfill();

        window.addEventListener('scroll', this.handleScroll);

        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        this.setState({})
    }

    handleScroll = () => {
        const scrollY = window.scrollY; //Don't get confused by what's scrolling - It's not the window
        const navbar = document.querySelector("nav");

        const sections = document.querySelectorAll(".section");
        const scrollDistance = window.scrollY;
        sections.forEach((section, key) => {
            if (section.getBoundingClientRect().top <= scrollDistance && (section.getBoundingClientRect().top > -150 && section.getBoundingClientRect().top < 50)) {

                this.setState({
                    activeMenu: section.getAttribute('id')
                });
            }
        });

        if(navbar != null && this.state.isHome === true) {
            if (scrollY > 20) {
                if (navbar.classList.contains("is-sticky") === false) {
                    navbar.classList.add("is-sticky");
                }
            }
            else {
                if (navbar.classList.contains("is-sticky") === true) {
                    navbar.classList.remove("is-sticky");
                }
            }
        }
    };

    handleClickOutside = () => {

        this.setState({
            isOpen: false,
        });

    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });

    };

    scrollTo = (element) => {
        if(document.getElementById(element)) {
            this.setState({
                activeMenu: element
            });

            const elementPosition = document.getElementById(element).offsetTop;
            window.scrollTo({
                top: elementPosition,
                behavior: "smooth"
            });
        } else {
            switch (element) {
                case "home": window.location = "/"; break;
                case "articles": window.location = "/articles"; break;
                case "safety": window.location = "/safety"; break;
                case "agreements": window.location = "/agreements"; break;
                case "about": window.location = "/about"; break;
                default: break;
            }
        }
    };

    render() {
        return (
            <header>
                <Navbar expand="lg" fixed={'top'} className={this.state.isHome === false ? "is-sticky" : ""}>
                    <Container>
                        <NavbarBrand onClick={() => this.scrollTo('home')}>
                            <StaticImage src={logo} className="logoWhite" alt="logo" />
                            <StaticImage src={logoBlack} className="logoBlack" alt="logo" />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} ><span/></NavbarToggler>
                        <Collapse isOpen={this.state.isOpen} navbar className="menu-ul">
                            <Nav className={"ml-auto"} navbar>
                                <NavItem>
                                    <NavLink onClick={() => this.scrollTo('home')} className={(this.state.activeMenu === 'home') ? "active" : ""}>Home</NavLink>
                                </NavItem>
                                {/*<NavItem hidden={!this.state.isHome}>
                                    <NavLink onClick={() => this.scrollTo('services')} className={(this.state.activeMenu === 'services') ? "active" : ""}>Services</NavLink>
                                </NavItem>*/}
                                <NavItem hidden={!this.state.isHome}>
                                    <NavLink onClick={() => this.scrollTo('quote')} className={(this.state.activeMenu === 'quote') ? "active" : ""}>Get a Quote</NavLink>
                                </NavItem>
                                <NavItem hidden={!this.state.isHome}>
                                    <NavLink onClick={() => this.scrollTo('shop')} className={(this.state.activeMenu === 'shop') ? "active" : ""}>Shop</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.scrollTo('articles')} className={(this.state.activeMenu === 'articles') ? "active" : ""}>Articles</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.scrollTo('safety')} className={(this.state.activeMenu === 'safety') ? "active" : ""}>Technical & Safety</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.scrollTo('agreements')} className={(this.state.activeMenu === 'agreements') ? "active" : ""}>Agreements</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.scrollTo('about')} className={(this.state.activeMenu === 'about') ? "active" : ""}>About</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.scrollTo('contact')} className={(this.state.activeMenu === 'contact') ? "active" : ""}>Contact</NavLink>
                                </NavItem>

                                {/*<NavItem>
                                    <NavLink onClick={() => this.scrollToTop('team')} className={(this.state.activeMenu === 'team') ? "active" : ""}>Team</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.scrollTo('portfolio')} className={(this.state.activeMenu === 'portfolio') ? "active" : ""}>Portfolio</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => this.scrollToTop('price')} className={(this.state.activeMenu === 'price') ? "active" : ""}>Price</NavLink>
                                </NavItem>
                                /*<NavItem>
                                    <NavLink onClick={() => this.scrollToTop('blog')} className={(this.state.activeMenu === 'blog') ? "active" : ""}>Blog</NavLink>
                                </NavItem>*/}
                            </Nav>

                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}


export default Tdsheader;
