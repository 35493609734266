import {Button, Col, Container, Form, FormGroup, Input, Media, Row} from "reactstrap";
import React, {useContext, useEffect, useState} from "react";
import {ReferenceDataContext} from "../businessLogic/ReferenceDataContext";
import emailjs from "emailjs-com";

import "../components/Tdscontact.css"

const MessageSubmitStatus = {
    SUBMITTING: "submitting",
    NOT_SUBMITTED: "notSubmitted",
    SUCCESS: "success",
    FAILURE: "failure",
};

export default function Tdscontact() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [messageSubmitStatus, setMessageSubmitStatus] = useState(MessageSubmitStatus.NOT_SUBMITTED);

    const context = useContext(ReferenceDataContext);

    useEffect(() => {
        if(typeof window !== 'undefined') {
            emailjs.init("user_eeF8OQFcgsQzMVdUjTSsy");
        }
    }, [context]);

    function submit() {
        if(typeof window !== 'undefined') {
            setMessageSubmitStatus(MessageSubmitStatus.SUBMITTING);
            emailjs.send('service_r5owgql', 'template_6aigmcb', {
                name: name,
                email: email,
                message: message,
            }, 'user_eeF8OQFcgsQzMVdUjTSsy')
                .then((_) => {
                    setMessageSubmitStatus(MessageSubmitStatus.SUCCESS);
                }, (_) => {
                    setMessageSubmitStatus(MessageSubmitStatus.FAILURE);
                });
        }
    }

    return (
        <Container>
            <div className="title-box">
                <h2>Contact <b>Us</b></h2>
                <p>
                    Opal Hydraulic is here to answer any enquiries you may have. <br/>
                    you can either visit us or send us a message.
                </p>
            </div>
            <Row>
                <Col xs="12" md="6" className={"mb-4"}>
                    <Media className="address-box">
                        <Media left href="mailto:opalhydraulic@gmail.com" >
                            <i className="icofont-mail" />
                        </Media>
                        <Media body>
                            <Media heading>
                                Email
                            </Media>
                            <a href="mailto:opalhydraulic@gmail.com">opalhydraulic@gmail.com</a>
                            <br/>
                        </Media>
                    </Media>
                </Col>
                <Col xs="12" md="6" className={"mb-4"}>
                    <Media className="address-box">
                        <Media left href="https://instagram.com/opalhydraulic?utm_medium=copy_link" >
                            <i className="icofont-instagram" />
                        </Media>
                        <Media body>
                            <Media heading>
                                Instagram
                            </Media>
                            <a href="https://instagram.com/opalhydraulic?utm_medium=copy_link">@opalhydraulic</a>
                            <br/>
                        </Media>
                    </Media>
                </Col>
                <Col xs="12" md="6" className={"mb-4"}>
                    <Media className="address-box">
                        <Media left href="sms:+61402273628" >
                            <i className="icofont-phone" />
                        </Media>
                        <Media body>
                            <Media heading>
                                SMS
                            </Media>
                            <a href="sms:+61402273628">+61 402 273 628</a>
                            <br/>
                        </Media>
                    </Media>
                </Col>
                <Col xs="12" md="6" className={"mb-4"}>
                    <Media className="address-box">
                        <Media left href="https://goo.gl/maps/CzkjCDrYQMDGTUkG8" >
                            <i className="icofont-location-pin" />
                        </Media>
                        <Media body>
                            <Media heading>
                                Address
                            </Media>
                            <p>
                                <a href="https://goo.gl/maps/CzkjCDrYQMDGTUkG8">
                                    U 16 15 Stud Road, Bayswater, VIC 3153
                                </a>
                            </p>
                        </Media>
                    </Media>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs="12" md="12" lg="4">
                    <h4>Get in Touch</h4>
                    <hr />
                    <p>
                        Send us a message about any questions or enquiries you may have and we endeavour to
                        get back to you within 24 hours. <br/>
                        Alternatively, you can email us using the email address provided.
                    </p>
                </Col>
                <Col xs="12" md="12" lg="8">
                    <Form className="mt-4">
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Input type="text" name="name" placeholder="Name"
                                           onChange={(e) => {setName(e.target.value)}} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Input type="email" name="email" placeholder="Email"
                                           onChange={(e) => {setEmail(e.target.value)}} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Input type="textarea" name="message" placeholder="Your message"
                                   onChange={(e) => {setMessage(e.target.value)}} />
                        </FormGroup>
                        <Row>
                            <Col xs={"12"} sm={"5"} md={"4"} className={"mb-2"} >
                                <Button color="primary" onClick={submit}
                                        disabled={messageSubmitStatus === MessageSubmitStatus.SUBMITTING
                                        || messageSubmitStatus === MessageSubmitStatus.SUCCESS
                                        || name === "" || email === "" || message === ""}>
                                    {
                                        messageSubmitStatus === MessageSubmitStatus.SUBMITTING ?
                                            <>
                                                        <span className="spinner-grow spinner-grow-sm mr-1" role="status"
                                                              aria-hidden="true"/>
                                                Sending ...
                                            </>
                                            :
                                            "Send Message"
                                    }

                                </Button>
                            </Col>
                            <Col xs={"12"} sm={"7"} md={"8"} className={"my-auto"}>
                                {messageSubmitStatus === MessageSubmitStatus.SUCCESS ?
                                    <p className={"my-auto align-middle text-success"}>
                                        Thank you, your message has been sent!
                                    </p>
                                    : messageSubmitStatus === MessageSubmitStatus.FAILURE ?
                                        <p className={"my-auto align-middle text-danger"}>
                                            There was an error in sending your message. Please try again.
                                        </p>
                                        : ""
                                }
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}