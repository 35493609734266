import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './Tdsfooter.css';


class Tdsfooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: (new Date()).getFullYear()
        };
    }

    render() {
        return (

            <footer>
                <Container>
                    <Row className="align-items-center">
                        <Col xs="12">
                            <p className="text-center">
                                Copyright <i className="icofont-copyright"/> {this.state.date} Opal Hydraulic. All rights reserved.
                                <a href={"/terms"} rel="noreferrer">Terms of Service.</a>
                            </p>
                        </Col>
                    </Row>
                </Container>

            </footer>
        );

    }


}

export default Tdsfooter;
